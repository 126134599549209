import React from 'react'
import { Formik } from 'formik'

import axiosApi from 'utils/axiosApi'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import Button from 'react-bootstrap/Button'
import { Input } from 'components/Common/input'
import { LookupsBlockContainer, LookupsTitle, GuidelineRowForm } from './styles'

export const DataManagementGuidelineBlock = ({ allJobs, update }) => {
  const today = dayjs().startOf('day')

  const inputDateFormat = 'YYYY-MM-DD'
  return (
    <LookupsBlockContainer>
      <LookupsTitle>Guideline</LookupsTitle>

      <Formik
        initialValues={{
          effectiveDate: today.format(inputDateFormat),
        }}
        onSubmit={async values => {
          await axiosApi.post('jobs/', {
            definition: 'SCAN_BONDS_FLOW',
            data: values,
          })
        }}
      >
        {({ values, handleSubmit, handleChange }) => (
          <GuidelineRowForm>
            <Input
              name="effectiveDate"
              type="date"
              label={null}
              max={today.format(inputDateFormat)}
              value={values.effectiveDate}
              onChange={handleChange}
            />
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Run Bonds-Flow
            </Button>
          </GuidelineRowForm>
        )}
      </Formik>
    </LookupsBlockContainer>
  )
}

DataManagementGuidelineBlock.propTypes = {
  allJobs: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  formatTime: PropTypes.func.isRequired,
}
