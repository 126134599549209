import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import dayjs from 'dayjs'
import find from 'lodash/find'
import axiosApi from 'utils/axiosApi'
import { LoadingIcon } from 'components/Common/button/styles'

import { Input } from 'components/Common/input'
import { Select } from 'components/Common/Select'
import { BoldText } from 'styles/Typography'
import { ConfirmDialog } from 'components/Common/ConfirmDialog'

import { DataRow } from './DataRow'

import { userProps } from '../types'

import { ServerError, UpdateFormWrapper, FormTitle, UserName, DataRowWrapper, StyledModalHeader, FlexContainer, StyledButton } from './styles'

export const UserUpdateForm = ({ user, show, onUpdate, onClose, onDelete }) => {
  const { t } = useTranslation()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [resetPasswordError, setResetPasswordError] = useState('')
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false)

  const subscriptionsTypes = [
    { value: 'trial', label: t('trial') },
    { value: 'active', label: t('active') },
    { value: 'blocked', label: t('blocked') },
  ]

  const defaultLangs = [
    { value: 'en', label: 'English' },
    { value: 'hb', label: 'Hebrew' },
  ]

  const loginMethods = [
    { value: 'sms', label: 'sms' },
    { value: 'password', label: 'password' },
  ]

  const sendResetPasswordEmail = async (email, lang) => {
    setResetPasswordLoading(true)
    const resePasswordEmailProperties = { email, lang: lang }
    try {
      await axiosApi.post('reset_password/send-email', resePasswordEmailProperties)
      setDeleteDialogOpen(false)
      setResetPasswordLoading(false)
      onClose()
    } catch (error) {
      setResetPasswordError(error.response.statusText)
      setResetPasswordLoading(false)
      setTimeout(() => {
        setResetPasswordError('')
      }, 3000)
    }
  }

  const getLoginMethod = user => {
    return user?.loginMethod
  }


  return (
    <Modal
      show={show}
      onHide={() => {
        setDeleteDialogOpen(false)
        onClose()
      }}
    >
      <>
        <StyledModalHeader closeButton />
        <ConfirmDialog
          isOpen={deleteDialogOpen}
          text={t('userForm.deleteUser')}
          textColor="red"
          buttons={[
            {
              text: t('userManagment.confirmDialog.cancelText'),
              color: 'outline-secondary',
              onClick: () => setDeleteDialogOpen(false),
            },
            {
              text: t('userManagment.confirmDialog.confirmText'),
              color: 'outline-danger',
              onClick: () => {
                setDeleteDialogOpen(false)
                onDelete(user._id)
              },
            },
          ]}
        />

        <FormTitle>{t('userManagment.userDetails')}</FormTitle>

        <Formik
          initialValues={{
            subscription: user?.subscription || '',
            stockSubscription: find(user?.subsPerSecType, { secType: 'stock' })?.sub || 'trial',
            allBondSubscription: find(user?.subsPerSecType, { secType: 'allBond' })?.sub || 'trial',
            defaultLanguage: user?.defaultLanguage || 'en',
            phone: user?.phone || '',
            loginMethod: getLoginMethod(user),
          }}
          onSubmit={(values, actions) => {
            onUpdate(user._id, { ...user, ...values }, actions, onClose)
            setDeleteDialogOpen(false)
          }}
        >
          {({ handleSubmit, handleChange, values, errors, touched, handleBlur }) => {
            return (
              <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                <UpdateFormWrapper>
                  <UserName>
                    {t('userForm.name.label')}:{user.email}
                  </UserName>
                  <DataRow
                    field
                    label={t('userForm.subscriptionType')}
                    content={
                      <Select
                        name="subscription"
                        getChangedValueWithEvent
                        value={values.subscription}
                        data={subscriptionsTypes}
                        onChange={handleChange}
                      />
                    }
                  />
                  <DataRow
                    field
                    label={t('userForm.stockSubscription')}
                    content={
                      <Select
                        name="stockSubscription"
                        getChangedValueWithEvent
                        value={values.stockSubscription}
                        data={subscriptionsTypes}
                        onChange={handleChange}
                      />
                    }
                  />
                  <DataRow
                    field
                    label={t('userForm.allBondSubscription')}
                    content={
                      <Select
                        name="allBondSubscription"
                        getChangedValueWithEvent
                        value={values.allBondSubscription}
                        data={subscriptionsTypes}
                        onChange={handleChange}
                      />
                    }
                  />
                  <DataRow
                    label={t('userForm.tosTime')}
                    content={<BoldText>{user.tosTime && dayjs(user.tosTime).format('DD/MM/YYYY, h:mm:ss a')}</BoldText>}
                  />
                  <DataRow label={t('userForm.tosVersion')} content={<BoldText>{user.tosVersion && user.tosVersion}</BoldText>} />
                  <DataRow
                    label={t('userForm.createdAt')}
                    content={<BoldText>{user.createdAt && dayjs(user.createdAt).format('DD/MM/YYYY, h:mm:ss a')}</BoldText>}
                  />
                  <DataRow
                    label={t('userForm.lastUpdate')}
                    content={<BoldText>{user.updatedAt && dayjs(user.updatedAt).format('DD/MM/YYYY, h:mm:ss a')}</BoldText>}
                  />
                  <DataRow
                    field
                    label={t('userForm.defaultLanguage')}
                    content={
                      <Select
                        name="defaultLanguage"
                        value={values.defaultLanguage}
                        getChangedValueWithEvent
                        data={defaultLangs}
                        onChange={handleChange}
                      />
                    }
                  />

                  <DataRow
                    field
                    label={t('userForm.loginMethod')}
                    content={
                      <Select name="loginMethod" getChangedValueWithEvent value={values.loginMethod} data={loginMethods} onChange={handleChange} />
                    }
                  />
                  <DataRow
                    field
                    label={t('userForm.phone.label')}
                    content={
                      <Input
                        name="phone"
                        type="string"
                        onBlur={handleBlur}
                        placeholder={t('userForm.phone.placeholder')}
                        value={values.phone}
                        onChange={handleChange}
                        lpignore={true}
                      />
                    }
                  />

                  <DataRowWrapper>
                    <FlexContainer>
                      <StyledButton type="submit">{t('update')}</StyledButton>
                      <StyledButton variant="danger" onClick={() => setDeleteDialogOpen(true)}>
                        {t('delete')}
                      </StyledButton>
                      <StyledButton variant="warning" onClick={async () => sendResetPasswordEmail(user.email, values.defaultLanguage)}>
                        {t('reset_password')}
                        {resetPasswordLoading && <LoadingIcon />}
                      </StyledButton>
                    </FlexContainer>
                  </DataRowWrapper>
                  {resetPasswordError && <ServerError>{t(resetPasswordError)}</ServerError>}
                  {errors.general && <ServerError>{errors.general}</ServerError>}
                </UpdateFormWrapper>
              </Form>
            )
          }}
        </Formik>
      </>
    </Modal>
  )
}

UserUpdateForm.defaultProps = {
  user: null,
}

UserUpdateForm.propTypes = {
  user: userProps,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
}
